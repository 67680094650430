var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReadOnly
    ? _c(
        "FormulateInput",
        _vm._b(
          {
            class: _vm.required ? "required" : "",
            attrs: {
              type: "label",
              label: _vm.label,
              validation: _vm.required ? "required" : "",
            },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          },
          "FormulateInput",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "FormulateInput",
        _vm._b(
          {
            class: _vm.required ? "required" : "",
            attrs: {
              label: _vm.label,
              type: "text",
              validation:
                (_vm.required ? "^required|" : "optional|") +
                "matches:/^[0-9]+$/",
            },
            on: { input: _vm.handleInput },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          },
          "FormulateInput",
          _vm.$attrs,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }