<template>
  <FormulateInput
    v-if="isReadOnly"
    v-model="content"
    v-bind="$attrs"
    type="label"
    :label="label"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
  />
  <FormulateInput
    v-else
    v-model="content"
    v-bind="$attrs"
    :label="label"
    type="text"
    :class="required ? 'required' : ''"
    :validation="`${required ? '^required|' : 'optional|'}matches:/^[0-9]+$/`"
    @input="handleInput"
  />
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      content: null,
    }
  },

  computed: {},

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    this.content = this.value
  },

  methods: {
    handleInput() {
      this.$emit('input', this.content)
    },
  },
}
</script>
